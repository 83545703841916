import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_6/subSlide';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(
        frontmatter: { language: { eq: "EN" }, title: { eq: "pilsudski-gang-interactive-photo" }, selector: {eq: "kazimierzSosnkowski"} }
      ) {
        body
      }
      file(relativePath: { eq: "chapter_4/sub_6/4-6-1-modal4.jpg" }) {
        childImageSharp {
          fixed(width: 230) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      }
    }
  `);
  return <Template query={query} />;
};

export default Slide;
